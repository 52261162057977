/*
 * @Author: chenz
 * @Date: 2021-11-18 10:30:15
 * @LastEditors: chenz
 * @LastEditTime: 2021-11-18 13:50:27
 * @Description: Do not edit
 * @FilePath: \elementui-vue\src\api\order.js
 */
import request from "./http"

/**
 * @description 查询分页数据
 *  @param  {String} limit 多少页
 *  @param  {String} page 第几页
 * @return 返回响应数据
 */
export function getStoreOrderList(limit, page, data) {
    return request({
        url: `/api/admin/storeOrder/list?limit=${limit}&page=${page}`,
        method: "post",
        data
    })
}


/**
 * @description 删除单条记录
 *  @param  {String} id 订单id
 * @return 返回响应数据
 */
export function delOrder(id) {
    return request({
        url: `/api/admin/storeOrder/delete/${id}`,
        method: "post"
    })
}


/**
 * @description 修改
 *  @param  {String} id 订单id
 *  @param  {Object} data 表单数据
 * @return 返回响应数据
 */
export function updateOrder(id, data) {
    return request({
        url: `/api/admin/storeOrder/update/${id}`,
        method: "post",
        data
    })
}


/**
 * @description 取消预约
 *  @param  {String} id 订单id
 *  @param  {Object} data 表单数据
 * @return 返回响应数据
 */
export function cancelOrder(orderId, userId) {
    return request({
        url: `/api/admin/storeOrder/cancel/${orderId}/${userId}`,
        method: "get",
    })
}