<!--
* @FileDescription: 订单管理
* @Author: chenz
* @Date: 2021/4/28
 * @LastEditors: chenz
 * @LastEditTime: 2021-11-18 13:55:43
-->
<template>
  <div class="order-manage">
    <!-- 查询表单 -->
    <CommonQueryFrom
      :queryForm="queryForm"
      :queryFormItemList="queryFormItemList"
      :isAdd="false"
      @_query="_query"
      @_export="_export"
    ></CommonQueryFrom>
    <!-- 表格 -->
    <CommonTable
      :tableData="tableData"
      :columns="columns"
      :loading="loading"
      :pagingOptions="pagingOptions"
      :isButtons="false"
      @_del="_del"
      @_edit="_edit"
      @_sizeChange="_sizeChange"
      @_currentChange="_currentChange"
      @_handleSelectionChange="_handleSelectionChange"
    >
      <el-table-column
        label="首页展示"
        slot="btn"
        prop="btn"
        align="center"
        width="100"
        :sortable="true"
      >
        <template slot-scope="scope">
          <el-popconfirm
            title="确定取消此预约订单吗？"
            @confirm="cancelOrder(scope.row)"
          >
            <el-button slot="reference" type="text">取消预约</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </CommonTable>
    <!-- 弹框表单 -->
    <CommonDialogForm
      ref="form"
      :form="form"
      :rules="rules"
      :visible="visible"
      :title="dialogTitle"
      :formItemList="formItemList"
      @_cancel="_cancel"
      @_confirm="_confirm"
      @_Close="_Close"
    >
    </CommonDialogForm>
  </div>
</template>

<script>
// 引入侧边栏json
const formItemList = require("./formItemList")

// 引入接口
import {
  getStoreOrderList,
  delOrder,
  updateOrder,
  cancelOrder,
} from "../../api/order"

// 引入方法
import { getExcelLabelOrProp } from "../../utils/tool"

export default {
  name: "OrderManage",

  data() {
    return {
      // 查询表单
      queryForm: {
        orderId: "",
        realName: "",
      },

      // 查询表单列表
      queryFormItemList: [
        { type: "input", prop: "orderId", placeholder: "请输入要查询的订单号" },
        {
          type: "input",
          prop: "realName",
          placeholder: "请输入要查询的用户姓名",
        },
      ],

      // 列接受的参数
      columns: [
        { label: "订单号", prop: "orderId", width: 120 },
        { label: "用户姓名", prop: "realName", width: 120 },
        { label: "详细地址", prop: "userAddress", width: 120 },
        { label: "店铺名", prop: "storeName", width: 120 },
        { label: "技师名", prop: "massagistName", width: 120 },
        { label: "订单总价", prop: "totalPrice" },
        { label: "实际支付", prop: "payPrice" },
        { label: "优惠金额", prop: "couponPrice " },
        { label: "订单开始时间", prop: "estimateStartTime" },
        { label: "订单结束时间", prop: "estimateEndTime" },
        { label: "订单状态", prop: "orderStatusText" },
        {
          slot: "btn",
        },
      ],

      // 具体数据
      tableData: [],

      // 选择的表格数据
      selectChangeTableData: [],

      // 分页
      pagingOptions: {
        limit: 10,
        page: 1,
        total: 0,
      },

      // 加载
      loading: false,

      // 弹框标题
      dialogTitle: "添加用户",

      // 弹框显示
      visible: false,

      // 表单
      form: {
        orderId: "",
        realName: "",
        userAddress: "",
        storeName: "",
        massagistName: "",
        totalPrice: "",
        payPrice: "",
        couponPrice: "",
        estimateStartTime: "",
        estimateEndTime: "",
        orderStatus: "",
      },

      // 校验
      rules: {
        orderId: [
          { required: true, message: "订单号不能为空", trigger: "change" },
        ],
        realName: [
          { required: true, message: "用户姓名不能为空", trigger: "change" },
        ],
        userAddress: [
          { required: true, message: "详细地址不能为空", trigger: "change" },
        ],
        storeName: [
          { required: true, message: "店铺名不能为空", trigger: "change" },
        ],
        massagistName: [
          { required: true, message: "技师名不能为空", trigger: "change" },
        ],
        totalPrice: [
          { required: true, message: "订单总价不能为空", trigger: "change" },
        ],
        payPrice: [
          { required: true, message: "实际支付不能为空", trigger: "change" },
        ],
        couponPrice: [
          { required: true, message: "优惠金额不能为空", trigger: "change" },
        ],
        estimateStartTime: [
          {
            required: true,
            message: "订单开始时间不能为空",
            trigger: "change",
          },
        ],
        estimateEndTime: [
          {
            required: true,
            message: "订单结束时间不能为空",
            trigger: "change",
          },
        ],
        orderStatus: [
          { required: true, message: "订单状态不能为空", trigger: "change" },
        ],
      },

      // 表单数据
      formItemList: formItemList,
    }
  },

  mounted() {
    this.getData()
  },

  methods: {
    _edit(row, table) {
      // console.log(1111, row, table)
    },

    // 查询
    _query(form) {
      this.getData()
    },

    // 导出
    _export(form) {
      console.log("导出", form)
      import("../../vendor/Export2Excel").then((excel) => {
        const tHeader = getExcelLabelOrProp("编号", "label", this.columns)
        const filterVal = getExcelLabelOrProp("serial", "prop", this.columns)
        const list =
          this.selectChangeTableData.length > 0
            ? this.selectChangeTableData
            : this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: "订单管理",
          bookType: "xlsx",
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]))
    },

    // 多选事件
    _handleSelectionChange(val) {
      this.selectChangeTableData = val
    },

    // 修改
    _edit(row) {
      this.dialogTitle = "修改用户"
      this.visible = true
      this.form = row
    },

    // 删除
    _del(row) {
      this.$confirm("你确定要删除此订单吗？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const res = await delOrder(row.id)
          if (res.code === 200) {
            this.getData()
          }
          this.$message({
            type: "success",
            message: "删除成功!",
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          })
        })
    },

    // 每页多少条事件
    _sizeChange(val) {
      this.pagingOptions.limit = val
      this.getData()
    },

    // 当前页事件
    _currentChange(val) {
      this.pagingOptions.page = val
      this.getData()
    },

    // 取消
    _cancel() {
      this.closeForm()
    },

    // 确定
    _confirm() {
      this.$refs.form.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const res = await updateOrder(this.form.id, this.form)
            // console.log("确定", res)
            this.visible = false
            this.$message({ type: "success", message: "修改成功" })
            this.getData()
          } catch (error) {
            this.$message({ type: "error", message: "修改失败" })
          }
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    // 表单关闭按钮
    _Close() {
      this.closeForm()
    },

    // 关闭表单
    closeForm() {
      // console.log(this.form)
      this.$refs.form.$refs.form.resetFields() // 重置表单
      this.$refs.form.$refs.form.clearValidate() // 移除表单校验
      this.visible = false // 关闭弹框
    },

    // 获取订单管理数据
    async getData() {
      const { limit, page } = this.pagingOptions
      this.loading = true
      try {
        const res = await getStoreOrderList(limit, page, {
          orderId: this.queryForm.inputVal1,
          realName: this.queryForm.inputVal2,
        })
        // console.log("获取订单管理数据", res)
        this.loading = false
        this.tableData = res.data.list
        this.tableData.forEach((_) => {
          var str = ""
          switch (_.orderStatus) {
            case 0:
              str = "已预约"
              break
            case 1:
              str = "进行中"
              break
            case 2:
              str = "待评价"
              break
            case 3:
              str = "已完成"
              break
            case 4:
              str = "已取消"
              break
          }
          _.orderStatusText = str
        })
        this.pagingOptions.total = res.data.total
      } catch (error) {
        this.loading = false
        this.tableData = []
        this.pagingOptions.total = 0
        this.$message({
          showClose: true,
          message: "获取数据失败",
          type: "error",
        })
      }
    },

    // 取消预约
    async cancelOrder(row) {
      const res = await cancelOrder(row.orderId, row.userId)
      this.getData()
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: res.message,
        })
      } else {
        this.$message({
          type: "error",
          message: res.message,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.order-manage {
  height: 100%;
  padding: 0 16px 0 16px;
  background-color: #fff;
}
</style>