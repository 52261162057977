/**
 * @description 获取表格的表头和表头对应的字段
 *  @param {String} 需要过滤掉的字段
 *  @param {String} 需要的属性
 *  @param {参数类型} 指定数组
 * @return 没有返回信息写 void / 有返回信息 {返回类型} 描述信息
 */
export function getExcelLabelOrProp(noNeedItem, key, arr) {
    var headers
    noNeedItem ? (headers = arr.map((_) => _[key]).filter((_) => _ !== noNeedItem)) : (headers = arr.map((_) => _[key]))
    return headers
}


/**
 * @description 获取性别
 *  @param {Number} sex 性别
 * @return 返回判断后的字段
 */
export function getSex(sex) {
    let str = ''
    switch (sex) {
        case 0:
            str = "未知"
            break
        case 1:
            str = "男"
            break
        case 2:
            str = "女"
            break
        case 3:
            str = "保密"
            break
    }
    return str
}


/**
 * @description 手机号正则校验
 *  @param {Object} rule 规则
 *  @param {String} value 值
 *  @param {Function} callback 规则
 * @return 手机号正则校验  如果错误返回错误信息
 */
export function getPhoneValidator(rule, value, callback) {
    // console.log('value=========',value);
    const regexp = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/
    if (!value) {
        callback(new Error('手机号不能为空'))
    }
    if (!regexp.test(value)) {
        callback(new Error('手机号格式不正确'))
    } else {
        callback()
    }
}


/**
 * @description 身份证号正则校验
 *  @param {Object} rule 规则
 *  @param {String} value 值
 *  @param {Function} callback 规则
 * @return 身份证号正则校验  如果错误返回错误信息
 */
export function getIDCardValidator(rule, value, callback) {
    const regexp = /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/
    // 如果不用必填 但是有值 做校验
    if (!rule.required && value) {
        if (!regexp.test(value)) {
            callback(new Error('身份证号格式不正确'))
        } else {
            callback()
        }
    }

    // 如果不用必填 但是没值 直接通过校验
    if (!rule.required && !value) {
        callback()
    }

    // 如果必填 校验
    if (rule.required) {
        if (!value) {
            return callback(new Error('身份证号不能为空'))
        }
        if (!regexp.test(value)) {
            return callback(new Error('身份证号格式不正确'))
        } else {
            callback()
        }
    }
}